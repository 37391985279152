import {Button, Card, Checkbox, Col, Form, FormInstance, message, Row, Space} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';

interface ModalType {
    importResult: number,
    cityName: string,
    cityId: number,
    hide: any,
    onCancel: any,
    activityId: number
}

const CorpsAddHospitalModalClass: React.FC<ModalType> = (props) => {
    const [selectHospital, setSelectHospital] = useState<[]>([]);
    // const [allViewIds, setAllViewIds] = useState<number[]>([]);
    const formRef = useRef<FormInstance>(null);
    // const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(
        function () {

            Fetch.get(`${API.aierCorps.aier_hospital_applets_config.find_add_hospital}?pId=${props.importResult}&cId=${props.cityId}&activityId=${props.activityId}`).then((data) => {
                const selectVos = data.selectVos;
                const currentIds = data.currentIds;
                // const allViewIds: number[] = selectVos.map((item: { value: number }) => item.value);
                setSelectHospital(selectVos)
                // setAllViewIds(allViewIds)
                const viewData = {hospitalIds: currentIds}
                formRef.current?.setFieldsValue({
                    ...viewData,
                });
            });
        },
        [props.importResult]
    );

    const cancel = () => {

        props.hide();
    }

    const submit = (values: any) => {

        values.cityId = props.cityId;
        values.activityId = props.activityId;
        Fetch.postJSON(API.aierCorps.aier_hospital_applets_config.batch_create, values).then(() => {
            message.success('提交成功');
            cancel()
        });
    }

    return (
        <>

            <Card title={props.cityName + "》添加医院"}>
                <Form
                    onFinish={submit}
                    ref={formRef}
                >
                    <Space direction="vertical">
                        <FormItem
                            name="hospitalIds"
                            label="当前省医院"
                            // labelCol={{span: 4}}
                            wrapperCol={{span: 15}}
                        >
                            <Checkbox.Group style={{width: '100%', padding: '5px'}}>
                                <Row gutter={[16, 16]}>
                                    {selectHospital.map((item: { value: number, label: string }) =>
                                        <Col>
                                            <Checkbox value={item.value}>{item.label}</Checkbox>
                                        </Col>
                                    )}
                                </Row>
                            </Checkbox.Group>
                        </FormItem>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                保存
                            </Button>
                            <Button
                                size="large"
                                style={{marginRight: '15px'}}
                                onClick={cancel}
                            >
                                取 消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </Card>
        </>
    );
};

export default CorpsAddHospitalModalClass;
