import React, {useEffect, useState} from 'react';
import styles from "../financialaudit/ServiceUser.module.css";
import {Button, Form, FormInstance, message, Select, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import {getZoneRoleId} from "../util/AierCommon";

type TransferType = {
    serviceId: number,
    hide: any,
    reload: any,
    activityId: number
}
/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function TransferModal(props: TransferType) {

    const formRef = React.createRef<FormInstance>();
    const [dictData, setDictData] = useState([{name: ''}]);
    const [submitButton, setSubmitButton] = useState(false);
    const [zoneSelect, setZoneSelect] = useState<any[]>([]);
    const [provinceId, setProvinceId] = useState<number>(0);
    const [hospitalSelect, setHospitalSelect] = useState<any[]>([]);
    const cancel = () => {

        props.hide();
    }
    const ok = () => {

        props.hide();
        props.reload();
    }
    const submit = (values: any) => {

        setSubmitButton(true)
        values.serviceId = props.serviceId;
        Fetch.putJSON(API.cooperateServiceUsersStatusV2.do_transfer_log, values).then(() => {
            message.success('提交成功');
            setSubmitButton(false)
            ok();
        });
    }
    useEffect(() => {

        loadSelectData()
    }, []);

    useEffect(() => {

        loadHospital()
    }, [provinceId]);

    const loadSelectData = () => {

        const zoneRoleId = getZoneRoleId(props.activityId);
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=${zoneRoleId}`;
        Fetch.get(url).then((data) => {
            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            setZoneSelect(resultList)
        });
    };

    const loadHospital = () => {

        if (provinceId === undefined || provinceId === 0) {
            return
        }
        Fetch.get(`${API.hospital_area.find_select_data}?provId=${provinceId}`).then((data) => {
            setHospitalSelect(data)
        });
    };

    const changeProvinceId = (e: any) => {

        setProvinceId(e)
        formRef.current?.setFieldValue("hospitalId", undefined)
    }

    return <>
        {dictData != null ? (
            <>
                <Form onFinish={submit}
                      ref={formRef}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                        <FormItem
                            className={styles.mylabel}
                            name="provinceId"
                            label="选择省份"
                            wrapperCol={{span: 25}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                showSearch
                                placeholder="选择省份"
                                optionFilterProp="children"
                                onChange={changeProvinceId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: '100%'}}
                                options={zoneSelect}
                            />
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            name="hospitalId"
                            label="选择医院"
                            wrapperCol={{span: 25}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                showSearch
                                placeholder="选择执行机构名称"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: '100%'}}
                                options={hospitalSelect}
                            />
                        </FormItem>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={submitButton}
                                className={styles.postSave}
                            >
                                保存
                            </Button>
                            <Button
                                size='large'
                                onClick={cancel}
                            >
                                取消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </>
        ) : ('')}
    </>;
}
