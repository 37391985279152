import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Radio} from 'antd';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import {Link, useParams} from 'react-router-dom';
import App from '../../App';
import styles from './detail.module.css';

import PersonCard from './component/DetailPerson';
import Educational from './component/Educational';
import Job from './component/Job';
import Active from './component/Active';
import Donation from './component/Donation';
import CertificateBusinessCard from './component/CertificateBusinessCard'

function VolunteerDetail1() {
    const {id, type} = useParams<{ id: string; type: string }>();

    // type 从哪个页面来的，id用户志愿者id，userid是用户id
    const [userData, setuserData] = useState<any>({});
    const [dictData, setDictData] = useState<any>({});
    const [WorkData, setWorkData] = useState<{}>({
        workHistoryList: [],
    });

    const [size, setSize] = useState<string>('1');
    // 生命周期
    useEffect(function () {
        getBasicData();
        getWorkData();
        getDict();
    }, []);
    const getBasicData = () => {
        Fetch.get(`${API.volunteer.volunteerBasicInfo}/${id}`).then((data) => {
            setuserData(data);
        });
    };
    const getWorkData = () => {
        Fetch.get(`${API.volunteer.volunteerWorkInfo}/${id}`).then((data) => {
            console.log(data)
            setWorkData(data);
        });
    };
    // 获取字典
    const getDict = () => {
        Fetch.get(`${API.volunteer.dict}`).then((data) => {
            setDictData(data);
        });
    };

    const onChange = (e: any) => {
        setSize(e.target.value);
    };
    // volunteerBasicInfo
    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>志愿者管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/volunteer">志愿者列表</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>志愿者详情</Breadcrumb.Item>
            </Breadcrumb>
            <div className={styles.content}>
                {/* 用户资料 */}
                <div className={styles.contentLeft + ' site-layout-background'}>
                    <div className={styles.margBot}>
                        <Radio.Group
                            value={size}
                            onChange={onChange}
                            style={{marginBottom: 16}}
                        >
                            <Radio.Button value="1">教育经历</Radio.Button>
                            <Radio.Button value="2">工作经历</Radio.Button>
                            <Radio.Button value="3">公益活动</Radio.Button>
                            <Radio.Button value="4">公益募捐</Radio.Button>
                            <Radio.Button value="5">证书名片</Radio.Button>
                        </Radio.Group>
                    </div>
                    {size === '1' ? (
                        <Educational datas={WorkData} dictData={dictData}></Educational>
                    ) : size === '2' ? (
                        <Job datas={WorkData} dictData={dictData}></Job>
                    ) : size === '3' ? (
                        <Active/>
                    ) : size === '4' ? (
                        <Donation/>
                    ) : size === '5' ? (
                        <CertificateBusinessCard/>
                    ) : (
                        ''
                    )}
                </div>
                {/* className={styles.contentRight} */}
                <div className={styles.contentRight}>
                    <PersonCard datas={userData} dictData={dictData}></PersonCard>
                </div>
            </div>
        </>
    );
}

export default function VolunteerDetail() {
    return (
        <App selectedKeys={['volunteer']} openKeys="vol">
            <VolunteerDetail1/>
        </App>
    );
}
