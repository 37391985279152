import React from 'react';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {BasePageData} from '../util/Corpstypes';
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {Button, Card, Col, DatePicker, Form, FormInstance, message, Row, Space, Spin} from "antd";
import FormItem from "antd/lib/form/FormItem";
import styles from "../hospitalauditv2/CorpsServiceUserV2.module.css";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {UploadOutlined} from "@ant-design/icons";
import FileUploader from "../../common/uploader";
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import ImageCropperVoucher from "./CorpsImageCropperVoucher";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";

class CorpsServiceUserVoucherClass extends React.Component<{}, BasePageData> {
    state = {
        releaseTime: null,
        fileUrl: null,
        batchAuditData: [],
        importLoading: false
    };
    formRef = React.createRef<FormInstance>();

    componentDidMount() {

    }

    submit = (values: any) => {

        this.setState({importLoading: true}, () => {
            values.fileId = values.files[0].id
            values.fileUrl = values.files[0].url
            Fetch.postJSON(API.aierCorps.cooperateServiceUsersStatusV2.get_batch_audit_data, values).then((data) => {
                if (data && data.length > 0) {
                    this.setState({
                        releaseTime: values.releaseTime,
                        fileUrl: values.fileUrl,
                        batchAuditData: data,
                        importLoading: false
                    })
                } else {
                    this.setState({
                        importLoading: false
                    })
                    message.error("未识别到任何内容。")
                }
            }).catch(() => {
                this.setState({
                    importLoading: false
                })
            });
        })
    }

    cancel = () => {

        window.location.href = "/#/corps/financialAuditList/auditList"
    }

    filesChange = (e: any) => {

        if (e.status === "done" || e.length === 0) {
            this.setState({batchAuditData: null, fileUrl: null})
        }
    }

    onChangeReleaseTime = (e: any) => {

        this.formRef.current?.setFieldValue("files", []);
        this.setState({batchAuditData: null, fileUrl: null})
    }

    disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };

    render() {
        const {fileUrl, batchAuditData} = this.state;

        return (
            <>
                <Spin tip={"识别中..."} spinning={this.state.importLoading}>
                <div>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <Card>
                                <Form
                                    onFinish={this.submit}
                                    ref={this.formRef}
                                >
                                    <Row gutter={20}>
                                        <Col span={10}>
                                            <FormItem
                                                className={styles.mylabel}
                                                name="releaseTime"
                                                label="打款时间"
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 15}}
                                                rules={[{required: true, message: '必填'}]}
                                            >
                                                <DatePicker
                                                    style={{width: '100%'}}
                                                    placeholder=""
                                                    onChange={this.onChangeReleaseTime}
                                                    disabledDate={this.disabledDate}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={10}>
                                            <FormItem
                                                className={styles.mylabel}
                                                name="files"
                                                label="打款凭证"
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 15}}
                                                valuePropName="fileList"
                                                rules={[{required: true, message: '必填'}]}
                                            >
                                                <FileUploader
                                                    multiple
                                                    listType="picture"
                                                    className="upload-list-inline"
                                                    accept='*'
                                                    checkSize={false}
                                                    noChekckSizeMal={true}
                                                    maxCount={1}
                                                    icon={<UploadOutlined/>}
                                                    onChange={this.filesChange}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={10}>
                                            <Space>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="middle"
                                                    className={styles.postSave}
                                                >
                                                    解析
                                                </Button>
                                                <Button
                                                    size='middle'
                                                    onClick={this.cancel}
                                                    className={styles.postSave}
                                                >
                                                    返回
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            <Card>
                                <ImageCropperVoucher fullImage={fileUrl}
                                                     serviceUserData={batchAuditData}
                                                     releaseTime={this.state.releaseTime}
                                />
                            </Card>
                        </Space>
                    </div>
                </div>
                </Spin>
            </>
        );
    }
}

export default function CorpsServiceUserVoucherList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <CorpsServiceUserVoucherClass/>
        </App>
    );
}
