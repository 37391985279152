import React, {useEffect, useState} from 'react';
import styles from "../financialaudit/CorpsServiceUser.module.css";
import {Button, Checkbox, Col, Form, FormInstance, message, Row, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";

/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function CorpsHospitalGroupEditDetail(props: any) {

    const formRef = React.createRef<FormInstance>();
    const [submitButton, setSubmitButton] = useState(false);
    const [hospitalSelect, setHospitalSelect] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {

        values.groupId = props.groupId;
        setSubmitButton(true)
        Fetch.postJSON(API.aierCorps.hospital_group.batch_update, values).then(() => {
            message.success('提交成功');
            setSubmitButton(false)
            ok();
        });
    }

    useEffect(() => {
        findHospitalGroupSelect()
    }, []);

    useEffect(() => {
        if (hospitalSelect) {
            formRef.current?.setFieldValue("hospitalIds", selectedIds)
        }
    }, [selectedIds]);

    const findHospitalGroupSelect = () => {

        const url = API.aierCorps.hospital_group.hospital_group_select + "?groupId=" + props.groupId;
        Fetch.get(url).then((data) => {
            setHospitalSelect(data.selectVos)
            setSelectedIds(data.selectedIds)
        })
    }

    return <>
        <Form onFinish={submit}
              ref={formRef}>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <FormItem
                    className={styles.mylabel}
                    name="hospitalIds"
                    label="医院名称"
                    rules={[{required: true, message: '必填'}]}
                >
                    <Checkbox.Group style={{width: '100%', padding: '5px'}}>
                        <Row gutter={[16, 16]}>
                            {hospitalSelect.map((item: { hospitalId: number, hospitalName: string }, index) =>
                                <Col span={8} key={index}>
                                    <Checkbox key={index} value={item.hospitalId}>{item.hospitalName}</Checkbox>
                                </Col>
                            )}
                        </Row>
                    </Checkbox.Group>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        loading={submitButton}
                        className={styles.postSave}
                    >
                        保存
                    </Button>
                    <Button
                        size='middle'
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </Space>
        </Form>
    </>;
}
