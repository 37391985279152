import React, {useEffect, useState} from 'react';
import styles from "../add/CorpsServiceUser.module.css";
import {accountRelationshipShow, paymentMethodShow} from "../util/CorpsAierCommon";
import {Button, Card, Descriptions, message, Modal, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";

type accountType = {
    paymentMethod: string | '',
    name: string | '',
    backCard: string | '',
    accountBank: string | '',
    relationship: string | ''
}
/**
 * 账户信息不一致弹窗
 *
 * @param props
 * @constructor
 */
const {confirm} = Modal;
export default function CorpsAccountsDifferent(props: any) {

    const [accountData, setAccountData] = useState<accountType[]>([]);
    const cancel = () => {
        props.hide();
    }

    const continueSubmit = () => {
        const id = props.selectId;
        confirm({
            title: `是否提交患者信息到基金会审核？`,
            okText: `提交`,
            onOk() {
                Fetch.putJSON(API.aierCorps.cooperateServiceUsersStatusV2.update_hospital_submissions, {
                    serviceUserId: id,
                    ignore: 1
                }).then(() => {
                    message.success('操作成功');
                    cancel();
                });
            },
        });
    }

    const overwriteCommits = () => {
        const id = props.selectId;
        confirm({
            title: `是否使用上次报名账户信息，同时提交患者信息到基金会审核？`,
            okText: `提交`,
            onOk() {
                Fetch.putJSON(API.aierCorps.cooperateServiceUsersStatusV2.update_hospital_submissions, {
                    serviceUserId: id,
                    cover: 1
                }).then(() => {
                    message.success('操作成功');
                    cancel();
                });
            },
        });
    }

    useEffect(() => {
        setAccountData(props.importResult);
    }, props.importResult);

    return <>
        {accountData != null && accountData.length > 0 ? (
            <>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Card size="small" className={styles.mycard}
                          title={`上次报名收款信息`}>
                        <Descriptions>
                            <Descriptions.Item label="收款方式">
                                {paymentMethodShow[accountData[0].paymentMethod]}
                            </Descriptions.Item>
                            <Descriptions.Item label="收款人与申请人的关系">
                                {accountRelationshipShow[accountData[0].relationship]}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="收款人姓名">
                                {accountData[0].name}
                            </Descriptions.Item>
                            <Descriptions.Item label="开户行名称">
                                {accountData[0].accountBank}
                            </Descriptions.Item>
                            <Descriptions.Item label="银行卡号">
                                {accountData[0].backCard}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <Card size="small" className={styles.mycard}
                          title={`本次报名收款信息`}>
                        <Descriptions>
                            <Descriptions.Item label="收款方式">
                                {paymentMethodShow[accountData[1].paymentMethod]}
                            </Descriptions.Item>
                            <Descriptions.Item label="收款人与申请人的关系">
                                {accountRelationshipShow[accountData[1].relationship]}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="收款人姓名">
                                {accountData[1].name}
                            </Descriptions.Item>
                            <Descriptions.Item label="开户行名称">
                                {accountData[1].accountBank}
                            </Descriptions.Item>
                            <Descriptions.Item label="银行卡号">
                                {accountData[1].backCard}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={continueSubmit}
                            className={styles.postSave}
                        >
                            提交审核
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={overwriteCommits}
                            className={styles.postSave}
                            danger
                        >
                            使用上次报名账户信息
                        </Button>
                        <Button
                            size='large'
                            onClick={cancel}
                        >
                            取消
                        </Button>
                    </Space>
                </Space>
            </>
        ) : ('')}
    </>;
}
