import React, {Component} from 'react';
import {Button, Card, Col, Descriptions, Form, FormInstance, Input, message, Modal, Row, Space, Spin} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import FormItem from 'antd/lib/form/FormItem';
import styles from "./CorpsHospitalAccountEdit.module.css"
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {FileWordFilled} from '@ant-design/icons';

const {confirm} = Modal;

class CorpsHospitalAccountEditClass extends Component {
    state = {
        // id: decryptData(common.getQuery('id')),
        data: {name: '', accountBank: '', accountNum: '', bankRnt: ''},
        hospital: [],
        selectHospital: {},
        isEdit: false,
        tip: "下载中...",
        importLoading: false
    };
    formRef = React.createRef<FormInstance>();

    componentDidMount() {

        this.getData();
    };

    getData() {
        Fetch.get(`${API.aierCorps.aier_hospital_account.get_detail}`).then((data) => {

            if (data) {
                this.setState({
                    data: data
                })
                if (this.formRef.current) {
                    this.formRef.current.setFieldsValue({
                        ...data,
                    });
                }
            }
        });
    };

    submit = (values: any) => {

        let that = this;
        confirm({
            title: `是否确认修改账户信息？`,
            onOk() {
                that.create(values);
            }
        });
    };

    create = (values: any) => {

        Fetch.postJSON(API.aierCorps.aier_hospital_account.create_or_update, values).then(() => {
            message.success('提交成功');
            window.location.reload();
        });
    };

    setIdEdit = () => {
        const {isEdit, data} = this.state;
        this.setState({
            isEdit: !isEdit
        })
        if (!isEdit) {
            this.formRef.current?.setFieldsValue({
                ...data,
            });
        }
    }

    download = () => {
        this.setState({
            importLoading: true,
        });
        const path = "https://appcdn.lelingzhiyuan.com/sys/doc/2023/10/30/f396f96a517c6ac3998c47da3f9b2899.docx";
        common.downloadFile("情暖兵团项目接受资金协议模板.docx", path, (callback: any) => {
            if (callback === true) {
                message.success('处理完成');
            } else {
                message.error('下载失败，请联系管理员');
            }
            this.setState({
                importLoading: false,
            });
        });
    }

    render() {
        // @ts-ignore
        const {data, isEdit} = this.state;
        return (
            <>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <Breadcrumb.Item>*/}
                {/*        银行账户*/}
                {/*    </Breadcrumb.Item>*/}
                {/*</Breadcrumb>*/}
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <div>

                            <Card size="small" style={{height: '720px'}}
                            >
                                <Form
                                    onFinish={this.submit}
                                    ref={this.formRef}
                                >
                                    {!isEdit ? (
                                        <>
                                            <Space>
                                                <Card className={styles.cardItem}>
                                                    <div className={styles.showDiv}>
                                                        <div>
                                                            <Descriptions labelStyle={{color: '#929294'}}
                                                                          className={styles.desc}>
                                                                <Descriptions.Item className={styles.descItem}
                                                                                   label="主体名称">
                                                                    {data.name}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions labelStyle={{color: '#929294'}}
                                                                          className={styles.desc}>
                                                                <Descriptions.Item className={styles.descItem}
                                                                                   label="开户行">
                                                                    {data.accountBank}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions labelStyle={{color: '#929294'}}
                                                                          className={styles.desc}>
                                                                <Descriptions.Item className={styles.descItem}
                                                                                   label="银行账号">
                                                                    {data.accountNum}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions labelStyle={{color: '#929294'}}
                                                                          className={styles.desc}>
                                                                <Descriptions.Item className={styles.descItem}
                                                                                   label="网点联行号">
                                                                    {data.bankRnt}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </div>
                                                        <Button className={styles.editButton}
                                                                onClick={this.setIdEdit}>编辑</Button>
                                                    </div>
                                                </Card>
                                                <Card className={styles.cardItem}>
                                                    <div>
                                                        <Space size={0}>
                                                            <FileWordFilled
                                                                style={{fontSize: '25px', color: '#1890ff'}}/>
                                                            <Button type={"link"} size={"small"}
                                                                    onClick={this.download}>情暖兵团项目接受资金协议模板.docx</Button>
                                                        </Space>
                                                        <Descriptions labelStyle={{color: '#929294'}}
                                                                      className={styles.desc}>
                                                            <Descriptions.Item className={styles.descItem}
                                                                               label="联系人">
                                                                赵立
                                                            </Descriptions.Item>

                                                        </Descriptions>
                                                        <Descriptions labelStyle={{color: '#929294'}}
                                                                      className={styles.desc}>
                                                            <Descriptions.Item className={styles.descItem}
                                                                               label="手机号">
                                                                18618353610
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Descriptions labelStyle={{color: '#929294'}}
                                                                      className={styles.desc}>
                                                            <Descriptions.Item className={styles.descItem}
                                                                               label="邮寄地址">
                                                                北京市西城区抄手胡同62号
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </div>
                                                </Card>
                                            </Space>
                                        </>
                                    ) : (
                                        <>
                                            <Row gutter={16}>
                                                <Col span={10}>
                                                    <FormItem
                                                        name="name"
                                                        label="主体名称"
                                                        labelCol={{span: 4}}
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        <Input placeholder="主体名称" maxLength={100}/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={10}>
                                                    <FormItem
                                                        name="accountBank"
                                                        label="开户行"
                                                        labelCol={{span: 4}}
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        <Input placeholder="开户行" maxLength={100}/>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={10}>
                                                    <FormItem
                                                        name="accountNum"
                                                        label="银行账号"
                                                        labelCol={{span: 4}}
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        <Input placeholder="银行账号" maxLength={100}/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={10}>
                                                    <FormItem
                                                        name="bankRnt"
                                                        label="网点联行号"
                                                        labelCol={{span: 4}}
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        <Input placeholder="网点联行号" maxLength={100}/>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Space style={{position: 'absolute', bottom: '20px'}}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                >
                                                    保存
                                                </Button>
                                                <Button
                                                    size="large"
                                                    onClick={this.setIdEdit}
                                                >
                                                    取消
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                </Form>
                            </Card>
                        </div>
                    </div>
                </Spin>
            </>
        )
            ;
    }
}

export default function CorpsHospitalAccountEdit() {
    return (
        <App selectedKeys={['HospitalAccountEditCorps']} openKeys="aierOphthalmologyCorps">
            <CorpsHospitalAccountEditClass/>
        </App>
    );
}
