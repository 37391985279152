import React, {useEffect, useState} from 'react';
import styles from "../add/CorpsServiceUser.module.css";
import {buildIllness, defaultData, eyeTypeArrShow} from "../util/CorpsAierCommon";
import {Button, Card, Col, Descriptions, Form, FormInstance, InputNumber, message, Modal, Row, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Decimal from "decimal.js";

const {confirm} = Modal;
/**
 * 报销弹窗
 * @param props
 * @constructor
 */
export default function CorpsUpdateServiceUserFee(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const [feeData, setFeeData] = useState(defaultData.data);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        confirm({
            title: `是否确认修改`,
            onOk() {
                values.serviceId = reason;
                Fetch.postJSON(API.aierCorps.aierHospitalFee.admin_update_fee, values).then(() => {
                    message.success('提交成功');
                    ok();
                });
            }
        })
    }

    const buildFile = (values: any) => {

        const files: any[] = [];
        addFiles(files, values.voucher, 8);
        values.files = files;
        delete values.voucher;
    }

    const addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };
    useEffect(() => {
        Fetch.get(`${API.aierCorps.aierHospitalFee.get_detail}?id=${reason}`).then((data) => {
            const illnessForm = buildIllness(data);
            if (illnessForm) {
                data.fee.eyeDiseases = illnessForm.eyeDiseases;
                data.fee.cataractEyeType = illnessForm.cataractEyeType;
                data.fee.fundusEyeType = illnessForm.fundusEyeType;
                data.fee.gilliumEyeType = illnessForm.gilliumEyeType;
            }
            setFeeData(data)
        });
    }, []);

    useEffect(() => {

        if (formRef.current) {
            formRef.current.setFieldsValue({
                ...feeData.fee,
            })
        }
    }, [formRef]);


    const isNumber = (value: number) => {

        return !isNaN(value) && value !== null && value !== undefined && isFinite(value);
    }

    const computeSelfPayPrice = () => {
        if (formRef.current) {
            const totalPrice = formRef.current.getFieldValue("totalPrice")
            const medicareReimbursement = formRef.current.getFieldValue("medicareReimbursement");
            const otherGrants = formRef.current.getFieldValue("otherGrants");
            if (isNumber(totalPrice) && isNumber(medicareReimbursement) && isNumber(otherGrants)) {
                formRef.current.setFieldsValue({
                    selfPayPrice: new Decimal(totalPrice).minus(new Decimal(medicareReimbursement)).minus(new Decimal(otherGrants))
                });
            } else {
                formRef.current.setFieldsValue({
                    selfPayPrice: null
                });
            }
        }
    }

    return <>
        {feeData != null && feeData.fee.timeOfAdmission != '' ? (
            <>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Card size="small" className={styles.mycard}
                          title={`费用信息`}>
                        <Form
                            onFinish={submit}
                            ref={formRef}
                        >
                            <Descriptions>
                                <Descriptions.Item label="眼病">
                                    {feeData.fee.illnessName?.replaceAll(',', ' ')}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="入院时间">
                                    {feeData.fee.timeOfAdmission}
                                </Descriptions.Item>
                                <Descriptions.Item label="出院时间">
                                    {feeData.fee.timeOfDischarge}
                                </Descriptions.Item>
                            </Descriptions>
                            <Row gutter={20}>
                                <Col span={10}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="totalPrice"
                                        label="总金额"
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 15}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <InputNumber
                                            // precision={2}
                                            style={{width: '100%'}}
                                            max={99999}
                                            min={0}
                                            onChange={computeSelfPayPrice}
                                            placeholder="总金额"
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={10}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="medicareReimbursement"
                                        label="医保报销"
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 15}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <InputNumber
                                            style={{width: '100%'}}
                                            max={99999}
                                            min={0}
                                            onChange={computeSelfPayPrice}
                                            placeholder="医保报销"
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={10}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="otherGrants"
                                        label="其他补助"
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 15}}
                                        rules={[{required: true, message: '必填'}]}
                                    >

                                        <InputNumber
                                            // precision={2}
                                            style={{width: '100%'}}
                                            max={99999}
                                            min={0}
                                            onChange={computeSelfPayPrice}
                                            placeholder="其他补助"
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={10}>

                                    <FormItem
                                        className={styles.mylabel}
                                        name="selfPayPrice"
                                        label="自付费用"
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 15}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <InputNumber
                                            // precision={2}
                                            style={{width: '100%'}}
                                            max={99999}
                                            min={0}
                                            placeholder="自付费用"
                                            disabled
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Descriptions>
                                {feeData.fee.cataractEyeType && (
                                    <>
                                        <Descriptions.Item label="白内障眼别">
                                            {eyeTypeArrShow[feeData.fee.cataractEyeType]}
                                        </Descriptions.Item>
                                    </>
                                )}
                                {feeData.fee.fundusEyeType && (
                                    <>
                                        <Descriptions.Item label="眼底病眼别">
                                            {eyeTypeArrShow[feeData.fee.fundusEyeType]}
                                        </Descriptions.Item>
                                    </>
                                )}
                                {feeData.fee.gilliumEyeType && (
                                    <>
                                        <Descriptions.Item label="翼状胬肉眼别">
                                            {eyeTypeArrShow[feeData.fee.gilliumEyeType]}
                                        </Descriptions.Item>
                                    </>
                                )}
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="备注">
                                    {feeData.fee.remark}
                                </Descriptions.Item>
                            </Descriptions>
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className={styles.postSave}
                                >
                                    保存
                                </Button>
                                <Button
                                    size='large'
                                    style={{marginRight: '15px'}}
                                    onClick={cancel}
                                >
                                    取消
                                </Button>
                            </Space>
                        </Form>
                    </Card>
                </Space>
            </>
        ) : ('')}
    </>;
}
