import {Card, Descriptions} from 'antd';
import React from 'react';
import styles from "../../add/CorpsServiceUser.module.css";
import {BillDetailCommonType} from "./types/CorpsHospitalBillType";
import {billStatus} from "../page/CorpsListCommon";

const BillInfo: React.FC<BillDetailCommonType> = (props) => {
    const {data} = props;
    return (
        <Card size="small" className={styles.mycard}
              title={`账单信息`}
              extra={<div>
                  账单状态: {billStatus[data.billDetail.billStatus]}
              </div>
              }>
            <Descriptions>
                <Descriptions.Item label="编码">
                    {data.billDetail.code}
                </Descriptions.Item>
                <Descriptions.Item label="账单月">
                    {data.billDetail.billMonth}
                </Descriptions.Item>
                <Descriptions.Item label="医院名称">
                    {data.billDetail.hospitalName}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions>
                <Descriptions.Item label="总金额">
                    {data.billDetail.totalRevenue}
                </Descriptions.Item>
                <Descriptions.Item label="医保报销">
                    {data.billDetail.totalHealthInsurance}
                </Descriptions.Item>
                <Descriptions.Item label="其他补助">
                    {data.billDetail.totalOtherGrants}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions>
                <Descriptions.Item label="医院垫付">
                    {data.billDetail.totalSubsidy}
                </Descriptions.Item>
                <Descriptions.Item label="患者自付">
                    {data.billDetail.totalSelfPay}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default BillInfo;
