import {HospitalBillDetail} from "./CorpsHospitalBillType";
import API from "../../../../common/api";


export const billDetailDefault: HospitalBillDetail = {
    billId: 0,
    data: {
        billDetail: {
            code: '',
            billMonth: '',
            hospitalId: 0,
            accountName: '',
            accountNum: '',
            billStatus: 0,
            numUser: 0,
            totalRevenue: 0,
            totalHealthInsurance: 0,
            totalOtherGrants: 0,
            totalSubsidy: 0,
            totalSelfPay: 0,
            hospitalName: '',
            receiptTime: null
        },
        detailVos: [
            {
                billId: 0,
                hospitalId: 0,
                serviceId: 0,
                userName: '',
                gender: 0,
                economicSituation: '',
                timeOfAdmission: '',
                timeOfDischarge: '',
                eyeType: 0,
                totalPrice: 0,
                medicareReimbursement: 0,
                selfPayPrice: 0,
                otherGrants: 0,
                subsidy: 0,
            }
        ],
        files: [
            {
                fileId: 0,
                type: 0,
                name: '',
                url: ''
            }
        ],
        fileClassify: {
            hasFile: false,
            stampBill: [],
            proofReimbursement: [],
            receipt: [],
            billFile: []
        },
        accountVo: {
            name: '',
            accountBank: '',
            accountNum: '',
            bankRnt: ''
        },
    }
}

export function showFile(data: HospitalBillDetail['data']) {

    data.fileClassify = {
        hasFile: false,
        stampBill: [],
        proofReimbursement: [],
        receipt: [],
        billFile: []
    };
    if (data.files && data.files.length > 0) {
        data.fileClassify.hasFile = true;
        data.files.forEach((item: { url: string; type: any; name: string, fileId: number }) => {
            const obj = {
                id: item.fileId,
                uid: item.fileId + "",
                fileId: item.fileId,
                name: item.name,
                url: API.domain + item.url,
            };
            if (item.type === 1) {
                data.fileClassify.stampBill.push(obj);
            } else if (item.type === 2) {
                data.fileClassify.proofReimbursement.push(obj);
            } else if (item.type === 3) {
                data.fileClassify.receipt.push(obj);
            } else if (item.type === 4) {
                data.fileClassify.billFile.push(obj);
            }
        });
    }
}
