import React, {Component} from 'react';
import {Button, Card, message, Modal, Space} from 'antd';
import API from '../../../common/api';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';
import {decryptData} from '../../../common/crypto';
import common from "../../../common/common";
import Fetch from "../../../common/FetchIt";
import {HospitalBillDetail} from "../hospitalbilldetail/types/CorpsHospitalBillType";
import {billDetailDefault, showFile} from "../hospitalbilldetail/types/CorpsHospitalDetailCommon";
import BillInfo from '../hospitalbilldetail/CorpsBillInfo';
import BillFileInfo from '../hospitalbilldetail/CorpsBillFileInfo';
import BillDetailInfo from '../hospitalbilldetail/CorpsBillDetailInfo';
import createModal from "../../../common/createModal";
import ReviewModal from "./CorpsReviewModal";
import BillAccountInfo from "../hospitalbilldetail/CorpsBillAccountInfo";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../../common/breadcrumb/BreadcrumbUtil";


const VarReviewModal = createModal(ReviewModal);

const {confirm} = Modal;
type User = {};

class CorpsFinancialBillAuditClass extends Component<{}, HospitalBillDetail> {

    state: HospitalBillDetail = {
        billId: decryptData(common.getQuery('billId')),
        data: billDetailDefault.data
    };
    private importResult: any = null;

    componentDidMount() {

        this.getData();
    }

    getData() {

        const {billId} = this.state;
        let url = `${API.aierCorps.service_user_bill.get_bill_detail}?billId=${billId}`;
        Fetch.get(url).then((data) => {
            showFile(data)
            this.setState({
                data: data,
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }

    updateMaterialApproved = () => {

        const {billId} = this.state;
        confirm({
            title: `是否审核通过？`,
            onOk() {
                const data = {
                    billId: billId
                }
                Fetch.putJSON(API.aierCorps.service_user_bill.update_material_approved, data).then(() => {
                    message.success('审核成功');
                    window.location.href = '/#/corps/serviceUserBill/financial_bill_list';
                });
            },
        });
    }

    updateMaterialReviewBounces = () => {
        this.importResult.show();
    }

    closeModal = () => {

    }

    render() {
        const {
            billId, data
        } = this.state;
        return (
            <>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <Breadcrumb.Item>*/}
                {/*        <Link to="/serviceUserBill/financial_bill_list">*/}
                {/*            报销名单Ⅱ*/}
                {/*        </Link>*/}
                {/*    </Breadcrumb.Item>*/}
                {/*    <Breadcrumb.Item>账单审核</Breadcrumb.Item>*/}
                {/*</Breadcrumb>*/}
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <BillInfo data={data}/>
                        <BillAccountInfo data={data}/>
                        <BillFileInfo data={data}/>
                        <Card>
                            <div>
                                <Button
                                    size="large"
                                    onClick={this.cancel}
                                >
                                    返回
                                </Button>
                                <Space style={{float: 'right'}}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={this.updateMaterialApproved}
                                    >
                                        通过
                                    </Button>
                                    <Button
                                        type="primary"
                                        danger
                                        size="large"
                                        onClick={this.updateMaterialReviewBounces}
                                    >
                                        拒绝
                                    </Button>
                                </Space>
                            </div>
                        </Card>
                        <BillDetailInfo billId={billId}/>
                    </Space>
                </div>
                <VarReviewModal
                    width={500}
                    title={'审核拒绝'}
                    id={this.state.billId}
                    url={API.aierCorps.service_user_bill.update_material_review_bounces}
                    successUrl={"/#/corps/serviceUserBill/financial_bill_list"}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
            </>
        );
    }
}

export default function CorpsHospitalBillList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <CorpsFinancialBillAuditClass/>
        </App>
    );
}
