import React, {useState} from 'react';
import styles from "../financialaudit/CorpsServiceUser.module.css";
import {Button, message, Modal, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";

const confirm = Modal.confirm;
type AuditModalType = {
    serviceId: number,
    hide: any,
    reload: any
}
/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function CorpsAuditModal(props: AuditModalType) {

    const [submitButton, setSubmitButton] = useState(false);
    const cancel = () => {

        props.hide();
    }
    const ok = () => {

        props.hide();
        props.reload();
    }
    const pass = () => {

        setSubmitButton(true)
        confirm({
            title: `是否确认通过？`,
            onOk() {
                Fetch.putJSON(API.aierCorps.cooperateServiceUsersStatusV2.update_patient_approved,
                    {serviceUserId: props.serviceId}).then(() => {
                    message.success('操作成功');
                    setSubmitButton(false)
                    ok();
                });
            },
            onCancel() {
                setSubmitButton(false)
            }
        })
    }

    const notPassed = () => {

        setSubmitButton(true)
        confirm({
            title: `是否确认退回？`,
            onOk() {
                Fetch.putJSON(API.aierCorps.cooperateServiceUsersStatusV2.update_patient_review_failed,
                    {serviceUserId: props.serviceId}).then(() => {
                    message.success('操作成功');
                    setSubmitButton(false)
                    ok();
                });
            },
            onCancel() {
                setSubmitButton(false)
            }
        })
    }

    return <>
        <div style={{height: '50px'}}/>
        <Space>
            <Button
                type="primary"
                htmlType="submit"
                size="middle"
                loading={submitButton}
                onClick={pass}
                className={styles.postSave}
            >
                通过
            </Button>
            <Button
                type="primary"
                danger
                htmlType="submit"
                size="middle"
                loading={submitButton}
                className={styles.postSave}
                onClick={notPassed}
            >
                退回
            </Button>
            <Button
                size='middle'
                onClick={cancel}
            >
                取消
            </Button>
        </Space>
    </>;
}
