import {Card, Descriptions} from 'antd';
import React from 'react';
import styles from "../add/CorpsServiceUser.module.css";
import {ActivityColor, ActivityMap, genderArr, listDefaultData} from "../util/CorpsAierCommon";
import {ServiceUserCommonType} from "../util/CorpsServiceUserDetailTypes"


const CorpsUserInfo: React.FC<ServiceUserCommonType> = (props) => {
    const {data} = props;
    return (
        <>
            {data && (
                <Card size="small" className={styles.mycard}
                      title={`个人信息`}
                      extra={<div>
                          患者状态: {listDefaultData.statusArr[data.user.status]}
                      </div>
                      }>
                    <Descriptions>
                        <Descriptions.Item label="姓名">
                            {data.user.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="性别">
                            {genderArr[data.user.gender]}
                        </Descriptions.Item>
                        <Descriptions.Item label="年龄">
                            {data.user.age}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        {data.user.nationality ? (
                            <Descriptions.Item label="民族">
                                {data.user.nationality}
                            </Descriptions.Item>
                        ) : ('')}
                        {data.user.dateBirth ? (
                            <Descriptions.Item label="出生年月">
                                {data.user.dateBirth}
                            </Descriptions.Item>
                        ) : ('')}
                        <Descriptions.Item label="联系电话">
                            {data.user.phone}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        {data.user.homeAddress ? (
                            <Descriptions.Item label="户籍地址">
                                {data.user.originAreaStr}
                            </Descriptions.Item>
                        ) : ('')}
                        {data.user.homeAddress ? (
                            <Descriptions.Item label="家庭住址">
                                {data.user.addressAreaStr} {data.user.homeAddress}
                            </Descriptions.Item>
                        ) : ('')}
                        {data.user.economicSituation ? (
                            <Descriptions.Item label="资助类型">
                                {data.user.economicSituation}
                            </Descriptions.Item>
                        ) : ('')}
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="身份证号码">
                            {data.user.idCard}
                        </Descriptions.Item>
                        {data.user.guardianName ? (
                            <Descriptions.Item label="监护人姓名">
                                {data.user.guardianName}
                            </Descriptions.Item>
                        ) : ('')}
                        {data.user.guardianPhone ? (
                            <Descriptions.Item label="监护人联系电话">
                                {data.user.guardianPhone}
                            </Descriptions.Item>
                        ) : ('')}
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="关联医院">
                            {data.user.hospitalName}
                        </Descriptions.Item>
                        <Descriptions.Item label="参与活动">
                            <div style={{
                                backgroundColor: (ActivityColor[data.user.activityId]),
                                padding: '0px 5px',
                                borderRadius: '15px',
                                color: 'white'
                            }}>
                                {ActivityMap[data.user.activityId]}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            )}
        </>
    );
};

export default CorpsUserInfo;
