import React from 'react';
import {Button, DatePicker, Space, Spin, Table, Select} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import {ActivityColor, ActivityMap, ActivitySelectList, listDefaultData} from "../util/AierCommon";
import encryptData from "../../common/crypto";
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {addParams, NUMBER_STR} from "../bill/page/ListCommon";
import {financialPageData} from "../bill/page/PageCommon";

type User = {};

const {RangePicker} = DatePicker;

class ReimbursedBatchListClass extends BaseListPage {
    state: PageData = {
        ...listDefaultData,
        btnQ: {
            ROLE_SERVICE_USERS_ROSTER_DETAIL: 0,
            ROLE_SERVICE_USERS_DOWNLOAD_T: 0
        },
        modalTitle: '导入结果'
    };
    private fileInput = document.getElementById('fileInput');

    componentDidMount() {
        super.componentDidMount();
    }

    loadData = () => {

        const {pageSize, searchData} = this.state;
        let url = `${API.reimbursed_batch.query_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("activityId", searchData.activityId, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    reset = () => {
        super.reset(listDefaultData.searchData);
    };


    render() {
        //
        const {
            searchData, pageSize, totalElements,
            content, btnQ
        } = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '活动名称',
                dataIndex: 'activityId',
                key: 'activityId',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <div style={{
                            backgroundColor: (ActivityColor[value]),
                            padding: '0px 5px',
                            borderRadius: '15px',
                            color: 'white'
                        }}>
                            {ActivityMap[value]}
                        </div>
                    );
                },
            },
            {
                title: '导出时间',
                width: 90,
                dataIndex: 'createdDate',
                key: 'createdDate',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '操作人',
                width: 80,
                dataIndex: 'username',
                key: 'username',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '编码',
                width: 100,
                dataIndex: 'code',
                key: 'code',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '已报销/报销失败/总人数',
                dataIndex: 'num',
                key: 'num',
                align: `center`,
                width: 100,
                render(value, record: any) {
                    return (
                        <span
                            style={{wordBreak: 'break-all'}}>{record.reimbursed + "/" + record.failedNum + "/" + record.num}</span>
                    );
                },
            },
            {
                title: '批次总金额（元）',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '已报销金额（元）',
                dataIndex: 'amountReimbursed',
                key: 'amountReimbursed',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '报销失败金额（元）',
                dataIndex: 'failedAmount',
                key: 'failedAmount',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },

            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {/*TOOD 权限*/}
                            {/*{record.num != record.reimbursed ? (*/}
                            {/*    <>*/}
                            {/*        <Button type="link" onClick={() => this.downloadTotal(record.id, record.code)}>*/}
                            {/*            下载未报销名单*/}
                            {/*        </Button>*/}
                            {/*    </>*/}
                            {/*) : ('')}*/}
                            {btnQ.ROLE_REIMBURSED_EXPORT_RECORDS_DETAIL ? (
                                <>
                                    <Link
                                        to={`/reimbursedBatchDetail/list?bId=${encryptData(record.id + "")}&code=${record.code}`}>
                                        详情
                                    </Link>
                                </>
                            ) : ('')}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            <Select
                                showSearch
                                placeholder="请选择活动"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, activityId: e})
                                }}
                                value={searchData.activityId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 240}}
                                options={ActivitySelectList}
                            />

                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={this.reset}>重置</Button>
                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function ReimbursedBatchList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ReimbursedBatchListClass/>
        </App>
    );
}
