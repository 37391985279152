import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Form, FormInstance, Input, InputNumber, message, Row, Select, Space} from 'antd';
import App from '../../../App';
import styles from './CorpsServiceUserV2.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import FileUploader from '../../common/uploader/FileUploader';
import FormItem from 'antd/lib/form/FormItem';
import {buildAierData, defaultData} from "../util/CorpsAierCommon";
import UserInfo from "../detail/CorpsUserInfo";
import {DetailClass} from "../util/CorpsServiceUserDetailTypes";
import IllnessInfo from "../detail/CorpsIllnessInfo";
import AccountInfo from "../detail/CorpsAccountInfo";
import {decryptData} from "../../common/crypto";
import WarVeteranInfo from "../detail/CorpsWarVeteranInfo";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";

const {TextArea} = Input;

class CorpsServiceUserHospitalScreeningClass extends Component<{}, DetailClass> {

    state: DetailClass = {
        id: decryptData(common.getQuery('id')),
        data: defaultData.data,
        snap: defaultData.snap,
        hospitalSelect: []
    };
    formRef = React.createRef<FormInstance>();
    refd = React.createRef<FileUploader>();

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.aierCorps.cooperateServiceUsers.get_patient_details}?id=${id}`).then((data) => {
            buildAierData(data);
            this.setState({
                data: data,
            });
            if (data.screening) {
                // @ts-ignore
                this.formRef.current.setFieldsValue({
                    ...data.screening,
                    screeningTime: dayjs(data.screening.screeningTime),
                })
            }
        });
    }

    submit = (values: any) => {
        values.serviceId = this.state.id;
        values.screeningTime = values.screeningTime.format('YYYY-MM-DD');
        this.create(values);
    }

    create = (values: any) => {

        Fetch.postJSON(API.aierCorps.aierHospitalScreening.do_save_hospital_screening, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    cancel = (values: any) => {
        window.history.go(-1);
    }

    render() {
        const {data, id, snap} = this.state;
        type User = {};
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <UserInfo data={data}/>
                        <WarVeteranInfo id={id} hospitalSelect={[]} snap={snap} data={data}/>
                        <IllnessInfo data={data}/>
                        <AccountInfo data={data}/>
                        <Card className={styles.mycard}
                              title={`检查登记`}>
                            <Form
                                onFinish={this.submit}
                                ref={this.formRef}
                            >
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="leftEyeVision"
                                            label="左眼视力"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <InputNumber style={{width: '100%'}}
                                                         min={0}
                                                         max={6}
                                                         placeholder="左眼视力"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="rightEyeVision"
                                            label="右眼视力"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <InputNumber style={{width: '100%'}} min={0} max={6}
                                                         placeholder="右眼视力"/>
                                            {/*<Input  placeholder="右眼视力"/>*/}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="screeningTime"
                                            label="检查时间"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <DatePicker style={{width: '100%'}}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="eligible"
                                            label="复诊建议"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="复诊建议"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: '具备手术适应症',
                                                    },
                                                    {
                                                        value: 2,
                                                        label: '不具备手术适应症',
                                                    }]}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="suggestion"
                                            label="救助类型评估"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <TextArea rows={4} placeholder="救助类型评估" maxLength={200}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="doctorName"
                                            label="责任医师"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input placeholder="责任医师"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className={styles.backBtn}
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size="large"
                                        className={styles.screeningSave}
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancel}
                                    >
                                        取 消
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                    </Space>

                </div>
            </div>
        );
    }
}

export default function CorpsServiceUserHospitalV2Screening() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <CorpsServiceUserHospitalScreeningClass/>
        </App>
    );
}
