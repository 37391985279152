import React from 'react';
import {Button, message, Modal, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {AppletsConfigPage, CorpsBtnQType} from '../util/Corpstypes';
import {appletsConfigListDefaultData, getDetBtnQ, listDefaultData} from "../util/CorpsAierCommon";
import BaseListPage from "../../common/listpage/BaseListPage";
import styles from "../hospitalauditv2/CorpsServiceUserV2.module.css";
import Fetch from "../../common/FetchIt";
import createModal from "../../common/createModal";
import EditHospitalRoleModal from "./CorpsEditHospitalRoleModal";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {addParams, NUMBER_STR} from "../bill/page/CorpsListCommon";


type User = { protocolStatus: number };
type BtnQType = {
    btnQ: CorpsBtnQType
}
const {confirm} = Modal;

const ImportResult = createModal(EditHospitalRoleModal);

class CorpsHospitalRoleListClass extends BaseListPage {
    state: AppletsConfigPage & BtnQType = {
        ...appletsConfigListDefaultData,
        btnQ: getDetBtnQ()
    };
    private importResult: any = null;

    componentDidMount() {
        super.componentDidMount();
        this.loadSelectData();
        this.loadHospital(null);
    }

    loadData = () => {

        const {pageSize, searchData} = this.state;
        let url = `${API.aierCorps.aier_hospital_role.list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("hospitalId", searchData.hospitalId, NUMBER_STR);
        url = url + addParams("provinceId", searchData.provinceId, NUMBER_STR);
        url = url + addParams("protocolStatus", searchData.protocolStatus, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    updateParticipate = (hospitalId: number) => {
        const that = this;
        confirm({
            title: `是否修改医院参与状态？`,
            okText: `提交`,
            onOk() {
                Fetch.postJSON(API.aierCorps.aier_hospital_role.update_participate, {hospitalId: hospitalId}).then(() => {
                    message.success('提交成功');
                    that.loadData();
                });
            }
        });
    }

    updateAdvanceMoney = (hospitalId: number, advanceMoney: boolean) => {
        const that = this;
        const title = advanceMoney ? "当前医院已经开启医院垫付是否修改" : "当前医院未开启医院垫付是否开启";
        confirm({
            title: title,
            okText: `提交`,
            onOk() {
                Fetch.postJSON(API.aierCorps.aier_hospital_role.update_advance_money, {hospitalId: hospitalId}).then(() => {
                    message.success('提交成功');
                    that.loadData();
                });
            }
        });
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    editHospitalViewRole = (provinceId: number, hospitalId: number) => {

        this.setState({
            provinceId: provinceId,
            selectHospitalId: hospitalId,
        }, () => {
            this.importResult.show()
        })
    }

    updateProtocol = (protocolStatus: number, hospitalId: number) => {

        const that = this;
        const title = protocolStatus === 1 ? "修改为已签约？" : "修改为未签约？";
        confirm({
            title: title,
            okText: `提交`,
            onOk() {
                Fetch.postJSON(API.aierCorps.aier_hospital_role.update_protocol, {hospitalId: hospitalId}).then(() => {
                    message.success('提交成功');
                    that.loadData();
                });
            }
        });
    }

    reset = () => {
        super.reset(listDefaultData.searchData);
        this.loadHospital(null);
    };

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=6`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.aierCorps.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    render() {
        //
        const {
            searchData, pageSize, totalElements,
            content, btnQ
        } = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '省',
                // width: 80,
                dataIndex: 'provinceName',
                key: 'provinceName',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '市',
                // width: 80,
                dataIndex: 'cityName',
                key: 'cityName',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '医院名称',
                // width: 100,
                dataIndex: 'hospitalName',
                key: 'hospitalName',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '医院类型',
                dataIndex: 'hospitalType',
                key: 'hospitalType',
                align: `center`,
                // width: 100,
                render(value: any, record: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value === 1 ? '省级' : '执行'}</span>
                    );
                },
            },
            {
                title: '是否参与',
                dataIndex: 'participate',
                key: 'participate',
                align: `center`,
                // width: 100,
                render(value: any, record: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value === true ? '参与' : '不参与'}</span>
                    );
                },
            },
            {
                title: '是否医院垫付',
                dataIndex: 'advanceMoney',
                key: 'advanceMoney',
                align: `center`,
                // width: 100,
                render(value: any, record: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value === true ? '是' : '否'}</span>
                    );
                },
            },
            {
                title: '签约',
                dataIndex: 'protocolStatus',
                align: `center`,
                width: 100,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all', color: value === 1 ? ("#FF0000") : ("#1677ff")}}>
                        {value === 1 ? ("未签") : ("已签")}
                    </span>;
                },
            },
            {
                title: '操作',
                key: 'operation',
                align: `center`,
                // width: 100,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <>
                                {btnQ.R331 && (
                                    <Button className={styles.antBtnPadding} type="link" size={"small"}
                                            onClick={() => this.updateProtocol(record.protocolStatus, record.hospitalId)}>
                                        {record.protocolStatus === 1 ? ("签约") : ("未签")}
                                    </Button>
                                )}

                                {btnQ.R332 && (
                                    <Button className={styles.antBtnPadding} type="link" size={"small"}
                                            onClick={() => this.editHospitalViewRole(record.provinceId, record.hospitalId)}>
                                        数据权限
                                    </Button>
                                )}
                                {record.hospitalType === 2 && (
                                    <>
                                        {btnQ.R333 && (
                                            <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                                    onClick={() => this.updateParticipate(record.hospitalId)}>
                                                {record.participate === true ? "不参与" : "参与"}
                                            </Button>
                                        )}
                                        {(record.participate === true && btnQ.R334) && (
                                            <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                                    onClick={() => this.updateAdvanceMoney(record.hospitalId, record.advanceMoney)}>
                                                修改垫付状态
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space className={styles.areaHospitalSelect}>
                            <Select
                                showSearch
                                placeholder="选择省份"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, provinceId: e})
                                }}
                                value={searchData.provinceId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 120}}
                                options={this.state.zoneSelect}
                            />
                            <Select
                                showSearch
                                placeholder="选择执行机构名称"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, hospitalId: e})
                                }}
                                value={searchData.hospitalId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 200}}
                                options={this.state.hospitalSelect}
                            />
                            <Select
                                showSearch
                                placeholder="签约状态"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, protocolStatus: e})
                                }}
                                value={searchData.protocolStatus}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 200}}
                                options={[{value: 1, label: "未签"}, {value: 2, label: "已签"}]}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <ImportResult
                    width={1500}
                    title={'编辑数据权限'}
                    provinceId={this.state.provinceId}
                    hospitalId={this.state.selectHospitalId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
            </>
        );
    }

}

export default function CorpsHospitalRoleList() {
    return (

        <App selectedKeys={
            ['hospitalRoleCorps']
        }
             openKeys="aierOphthalmologyCorps">
            <CorpsHospitalRoleListClass/>
        </App>
    );
}
