import {Card, Descriptions} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from "../add/CorpsServiceUser.module.css";
import {DetailClass} from "../util/CorpsServiceUserDetailTypes";
import {AierServiceIdentityMap} from "../util/CorpsAierCommon";


// 其他import语句

const CorpsWarVeteranInfo: React.FC<DetailClass> = (props) => {
    const {data} = props;

    const [warLabel, setWarLabel] = useState<any>({});
    useEffect(() => {
        if (data.warVeteran.serviceIdentity) {
            setWarLabel(AierServiceIdentityMap[data.warVeteran.serviceIdentity])
        }
    }, [data.warVeteran.serviceIdentity])

    return (
        <>
            <Card size="small" className={styles.mycard} title={`身份信息`}>
                <Descriptions>
                    <Descriptions.Item label={warLabel.cardTitle}>
                        {data.warVeteran.serviceIdentityStr}
                    </Descriptions.Item>
                    <Descriptions.Item label={warLabel.myself}>
                        {data.warVeteran.myself === '2' ? ('亲属') : (<>{warLabel.warVeteranShow}</>)}
                    </Descriptions.Item>
                </Descriptions>
                {data.warVeteran.myself === '2' && (
                    <>
                        <Descriptions>
                            <Descriptions.Item label={warLabel.warVeteranName}>
                                {data.warVeteran.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={warLabel.warVeteranIdNumber}>
                                {data.warVeteran.idNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label={warLabel.warVeteranPhone}>
                                {data.warVeteran.phone}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label={warLabel.warVeteranRelations}>
                                {data.warVeteran.veteranRelations}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                )}
            </Card>
        </>
    );
};

export default CorpsWarVeteranInfo;

