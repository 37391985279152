import {Card, Descriptions, Image} from 'antd';
import React from 'react';
import styles from "../add/CorpsServiceUser.module.css";
import {ServiceUserCommonType} from "../util/CorpsServiceUserDetailTypes"

const CorpsAccountInfo: React.FC<ServiceUserCommonType> = (props) => {
        const {data} = props;
        return (
            <>
                {data && data.account != null && data.account.accountBank != null ? (
                    <Card size="small" className={styles.mycard}
                          title={`银行账户`}>
                        {data.account.paymentMethod === "医院垫付" ? (
                            <Descriptions>
                                <Descriptions.Item label="收款方式">
                                    {data.account.paymentMethod}
                                </Descriptions.Item>
                            </Descriptions>
                        ) : (
                            <>
                                <Descriptions>
                                    <Descriptions.Item label="收款方式">
                                        {data.account.paymentMethod}
                                    </Descriptions.Item>
                                    {data.account.paymentMethod === "家属账户" ? (
                                        <>
                                            <Descriptions.Item label="联系电话">
                                                {data.account.phone}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="身份证号">
                                                {data.account.idNumber}
                                            </Descriptions.Item>
                                        </>
                                    ) : ('')}
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="收款人姓名">
                                        {data.account.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="开户行名称">
                                        {data.account.accountBank}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="银行卡号">
                                        {data.account.backCard}
                                    </Descriptions.Item>
                                </Descriptions>
                                {data.account.paymentMethod === "家属账户" ? (
                                    <>
                                        <Descriptions>
                                            <Descriptions.Item label="收款人与申请人的关系">
                                                {data.account.relationship}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions>
                                            <Descriptions.Item label="申请人户口本个人登记页">
                                                <Image
                                                    width={100}
                                                    src={data.account.applicantShow}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="收款人户口本个人登记页">
                                                <Image
                                                    width={100}
                                                    src={data.account.payeeShow}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="患者签名">
                                                <Image
                                                    width={100}
                                                    src={data.account.signatureShow}
                                                />
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                ) : ('')}
                            </>
                        )}
                    </Card>
                ) : ('')}
            </>
        );
    }
;

export default CorpsAccountInfo;
