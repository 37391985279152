import BaseListPage from "../../common/listpage/BaseListPage";
import API from "../../common/api";
import Fetch from "../../common/FetchIt";
import {BaseHospitalSelectPageType} from "../bill/page/CorpsPageTypeTypes";
import {BaseHospitalSelectPageData} from "../bill/page/CorpsPageCommon";


class AierHospitalSelectPage extends BaseListPage {

    state: BaseHospitalSelectPageType = {
        ...BaseHospitalSelectPageData
    }

    componentDidMount() {
        super.componentDidMount()
        this.loadSelectData();
        this.loadHospital(null);
    };

    changeCondition(condition: any) {
        super.changeCondition(condition);
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
    };

    pageChange(condition: any) {

        super.pageChange(condition);
    };

    handleKeyDown(e: any) {

        super.handleKeyDown(e);
    };

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=6`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });

    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.aierCorps.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    protected doLoadData(url: string, callback: { (data: any): void; (arg0: any): any; }) {

        super.doLoadData(url, callback);
    }

    protected reset(def: any) {

        super.reset(def)
        this.loadHospital(null);
    };
}

export default AierHospitalSelectPage;
