import {Card, Descriptions} from 'antd';
import React from 'react';
import styles from "../add/CorpsServiceUser.module.css";
import {eligibleArr} from "../util/CorpsAierCommon";
import {ServiceUserCommonType} from "../util/CorpsServiceUserDetailTypes"


const CorpsScreeningInfo: React.FC<ServiceUserCommonType> = (props) => {
        const {data} = props;
        return (
            <>
                {data &&
                data.screening != null && data.screening.leftEyeVision != null ? (
                    <Card size="small" className={styles.mycard}
                          title={`检查登记信息`}>
                        <Descriptions>
                            <Descriptions.Item label="左眼视力">
                                {data.screening.leftEyeVision}
                            </Descriptions.Item>
                            <Descriptions.Item label="右眼视力">
                                {data.screening.rightEyeVision}
                            </Descriptions.Item>
                            <Descriptions.Item label="检查时间">
                                {data.screening.screeningTime}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="救助类型评估">
                                {data.screening.suggestion}
                            </Descriptions.Item>
                            <Descriptions.Item label="复诊建议">
                                {eligibleArr[data.screening.eligible]}
                            </Descriptions.Item>
                            <Descriptions.Item label="责任医师">
                                {data.screening.doctorName}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                ) : (
                    ''
                )
                }
            </>
        );
    }
;

export default CorpsScreeningInfo;
