import React, {Component} from 'react';
import {Card, Space} from 'antd';
import App from '../../../App';
import styles from './Corpshospitalstats.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import AreaChart from "./CorpsAreaChart";
import HistogramChart from "./CorpsHistogramChart";
import ProvinceTable from "./CorpsProvinceTable";
import {Chart} from '../util/CorpsServiceUserDetailTypes';
import {statsDefaultData} from "../util/CorpsAierCommon";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import HospitalDayStats from "./CorpsHospitalDayStats";


class CorpsHospitalStatsIndexClass extends Component<{}, Chart> {

    cardRef = React.createRef<HTMLDivElement>();
    state: Chart = {
        data: statsDefaultData.data,
        dayStats: {
            provinceStats: [{
                name: '',
                totalUser: 0,
                completeSurgery: 0,
                completeReimburse: 0,
                completeSurgeryCases: 0,
                completeReimburseCases: 0,
                msg: '',
            }]
        }
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        if (this.cardRef.current) {
            const cardWidth = this.cardRef.current.offsetWidth - 298;
            this.getData(cardWidth)
        } else {
            this.getData(1000)
        }
    };

    getData(cardWidth: number) {
        let url = `${API.aierCorps.hospital_stats.get_stats_page}`;
        Fetch.get(url).then((data) => {
            if (data){
                data.offsetWidth = cardWidth;
                this.setState({
                    data: data,
                });
            }
        });
        // let url_day = `${API.aier_hospital_day.find_hospital_day_stats}`;
        // Fetch.get(url_day).then((data) => {
        //     this.setState({
        //         dayStats: data,
        //     });
        // });
    }

    render() {
        const {data, dayStats} = this.state
        const chartData = data.statsTotal;
        return (
            <div>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <>*/}
                {/*        <Breadcrumb.Item>*/}
                {/*            <Link to="/cooperateServiceUsers/addList">*/}
                {/*                统计图表*/}
                {/*            </Link>*/}
                {/*        </Breadcrumb.Item>*/}
                {/*    </>*/}
                {/*</Breadcrumb>*/}
                <BreadcrumbInfo/>
                <div ref={this.cardRef}
                     className="site-layout-background"
                     style={{padding: 24, minHeight: 720}}
                >
                    <Card>
                        {sessionStorage.orgName === '管理中心' ? (
                            <Space size={'large'} className={styles.chartBox}>
                                <AreaChart data={data} dayStats={dayStats}/>
                                <HistogramChart data={data} dayStats={dayStats}/>
                                {/*<DonutChart data={data}/>*/}
                            </Space>
                        ) : (
                            <AreaChart data={data} dayStats={dayStats}/>
                        )}
                    </Card>

                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <ProvinceTable data={data} dayStats={dayStats}/>
                        <HospitalDayStats data={data} dayStats={dayStats}/>
                    </Space>

                </div>
            </div>
        );
    }
}

export default function CorpsHospitalStatsIndex() {
    let keys = "hospitalStatsIndexCorps";
    return (
        <App selectedKeys={[keys]} openKeys="aierOphthalmologyCorps">
            <CorpsHospitalStatsIndexClass/>
        </App>
    );
}
