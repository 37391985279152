import React from 'react';
import {Area} from '@ant-design/charts';
import {Chart} from "../util/CorpsServiceUserDetailTypes";
import styles from './Corpshospitalstats.module.css';

const CorpsAreaChart: React.FC<Chart> = (props) => {


    const config = {
        data: props.data.last7Day || [],
        xField: 'key',
        yField: 'value',
        meta: {
            value: {
                alias: '报名人数',
            },
        },
        style: {
            width: sessionStorage.orgName === '管理中心' ? props.data.offsetWidth / 2 - 20 + 'px' : '100%'
        },
        xAxis: {
            tickCount: 5,
        },
        animation: false,
        slider: {
            start: 0.5,
            end: 1,
            trendCfg: {
                isArea: true,
            },
        },
    };
    // @ts-ignore
    return <>
        {props.data.last7Day && (
            <div className={styles.chartDiv}>
                <Area  {...config} />
            </div>
        )}
    </>;
};

export default CorpsAreaChart;
