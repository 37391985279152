import {
    BaseHospitalSelectPageType,
    BillBatchDetailType,
    BillHospitalList,
    CommonPage,
    FinancialPageType
} from "./CorpsPageTypeTypes";
import {getDetBtnQ} from "../../util/CorpsAierCommon";


export const commonPageData: CommonPage = {
    searchData: {
        page: 0
    },
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    content: [],
    btnQ: getDetBtnQ(),
    tip: '',
    importLoading: false
}

export const BaseHospitalSelectPageData: BaseHospitalSelectPageType = {

    ...commonPageData,
    searchData: {
        page: 0,
        hospitalId: undefined,
        provinceId: undefined
    },
    zoneSelect: [],
    hospitalSelect: []
}

export const financialPageData: FinancialPageType = {

    ...BaseHospitalSelectPageData,
    searchData: {
        ...BaseHospitalSelectPageData.searchData,
        status: undefined,
        billDay: undefined,
        protocolStatus: undefined
    },
    billId: 0,
    billShowStatusArr: [],
    expectedPayment: ''
}

export const billBatchDetail: BillBatchDetailType = {

    ...commonPageData,
    searchData: {
        page: 0,
        batchId: ''
    },
}


export const billHospitalListData: BillHospitalList = {

    ...BaseHospitalSelectPageData,
    searchData: {
        ...BaseHospitalSelectPageData.searchData,
        status: undefined,
        billDay: undefined
    },
    billShowStatusArr: [],
    advanceMoney: false,
    protocolStatus: 0
}


