import {Chart, CheckboxType, DetailClass, IllnessDetail, IllnessForm} from "./CorpsServiceUserDetailTypes";
import API from "../../common/api";
import {AppletsConfigPage, PageData} from "./Corpstypes";
import {statusArrV2, statusColorArrV2, statusSelectArrV2} from "./CorpsAierCommonStatusV2";
import {CATARACT, FUNDUS, GILLIUM} from "./CorpsEyeDiseasesUtil";

export const statusArr = {
    // 1: '等待检查',
    // 2: '具备手术适应症,等待患者申请补贴',
    // 3: '不具备手术适应症,患者无法申请补贴',
    // 4: '补贴申请待审核',
    // 5: '补贴申请审核通过',
    // 11: '补贴申请审核不通过',
    // 6: '手术已完成',
    // 7: '回访已完成',
    // 8: '报销申请待审核',
    // 9: '报销申请审核通过',
    // 12: '报销申请审核不通过',
    // 10: '已报销',
    1: '等待审核',
    13: '审核通过，等待医院检查',
    14: '审核不通过',
    2: '医院检查结束，等待手术',
    3: '医院检查不通过', //红
    6: '手术已完成，等待医院申请报销',
    8: '医院报销申请已提交',
    9: '报销申请审核通过，等待报销',
    12: '报销申请审核不通过',
    10: '已报销', //绿
}
export const statusColorArr = {
    1: 'black',
    13: 'black',
    14: 'black',
    2: 'black',
    3: 'red', //红
    6: 'black',
    8: 'black',
    9: 'black',
    12: 'black',
    10: 'green', //绿
}
// export const statusSelectArr = [
//     {
//         value: 1,
//         label: '等待审核',
//     },
//     {
//         value: 13,
//         label: '审核通过，等待医院检查',
//     },
//     {
//         value: 14,
//         label: '审核不通过',
//     },
//     {
//         value: 2,
//         label: '医院检查结束，等待手术',
//     },
//     {
//         value: 3,
//         label: '医院检查不通过',
//     },
//     {
//         value: 6,
//         label: '手术已完成，等待医院申请报销',
//     },
//     {
//         value: 8,
//         label: '医院报销申请已提交',
//     },
//     {
//         value: 10,
//         label: '已报销',
//     }]

export const genderArr = {
    1: '男',
    2: '女',
}

export const troupeStatusArr = {

    1: '待审核',
    2: '审核退回',
    3: '审核通过',
}
export const eligibleArr = {
    1: '具备手术适应症',
    2: '不具备手术适应症',
}

export const eyeTypeArrShow = {
    1: '左眼',
    2: '右眼',
    3: '双眼',
}
export const accountRelationshipShow = {
    '0': '患者本人',
    '1': '配偶',
    '2': '子女',
    '3': '兄弟姐妹',
}
export const paymentMethodShow = {
    1: '患者账户',
    2: '家属账户',
    3: '医院垫付',
}
export const eyeTypeArr = [
    {
        value: 1,
        label: '左眼',
    },
    {
        value: 2,
        label: '右眼',
    },
    {
        value: 3,
        label: '双眼',
    }]

export const PaymentMethodArr = [
    {
        value: "10",
        label: '个人垫付',
    },
    {
        value: "11",
        label: '医院垫付',
    }]

export const eyeTypeLeft = {
    value: 1,
    label: '左眼',
}
export const eyeTypeRight = {
    value: 2,
    label: '右眼',
}
export const eyeDiseasesArr: CheckboxType[] = [
    {
        value: 'CATARACT',
        label: '白内障',
        disabled: false
    },
    {
        value: 'FUNDUS',
        label: '眼底病',
        disabled: false
    },
    {
        value: 'GILLIUM',
        label: '翼状胬肉',
        disabled: false
    }]
const CATARACT_OBJ: CheckboxType = {
    value: 'CATARACT',
    label: '白内障',
    disabled: false
}

export function getCataract() {
    return JSON.parse(JSON.stringify(CATARACT_OBJ));
}

const FUNDUS_OBJ: CheckboxType =
    {
        value: 'FUNDUS',
        label: '眼底病',
        disabled: false
    }

export function getFundus() {
    return JSON.parse(JSON.stringify(FUNDUS_OBJ));
}

const GILLIUM_OBJ: CheckboxType =
    {
        value: 'GILLIUM',
        label: '翼状胬肉',
        disabled: false
    }

export function getGillium() {
    return JSON.parse(JSON.stringify(GILLIUM_OBJ));
}

export const defaultData: DetailClass = {
    id: null,
    snap: null,
    hospitalSelect: [],
    data: {
        user: {
            name: null,
            phone: null,
            idCard: null,
            createUserId: null,
            createInstitution: null,
            gender: 0,
            age: null,
            disabilityLevel: null,
            homeAddress: null,
            accountType: null,
            guardianName: null,
            guardianPhone: null,
            economicSituation: null,
            povertyType: null,
            hospitalId: null,
            hospitalName: null,
            status: 0,
            dateBirth: null,
            nationality: null,
            addressAreaStr: null,
            originAreaStr: null,
            illnessInfo: null,
            releaseTime: null,
            billId: 0,
            identityPassesType: null,
            activityId: 0
        },
        account: {
            name: null,
            phone: null,
            backCard: null,
            accountBank: null,
            hospitalsId: null,
            hospitalsName: null,
            cardUrl: null,
            paymentMethod: null,
            idNumber: null,
            relationship: null,
            applicant: [],
            payee: [],
            signature: [],
            applicantShow: '',
            payeeShow: '',
            signatureShow: '',
            paymentMethodId: '0',
            relationshipId: ''
        },
        files: [
            {
                fileId: 0,
                name: null,
                type: null,
                url: null
            }
        ],
        screening: {
            leftEyeVision: null,
            rightEyeVision: null,
            screeningTime: null,
            suggestion: null,
            doctorName: null,
            eligible: 0,
        },
        fee: {
            id: 0,
            timeOfAdmission: '',
            timeOfDischarge: '',
            eyeType: 0,
            totalPrice: null,
            medicareReimbursement: null,
            selfPayPrice: null,
            otherGrants: null,
            subsidy: null,
            subsidyTemp: null,
            remark: null,
            diagnosticReports: [],
            consentForms: [],
            hospitalizationRecords: [],
            invoices: [],
            voucher: [],
            identityPromise: [],
            proofProve: [],
            preferentialTreatmentCard: [],
            estimatedAmount: null,
            afterSelfPayPrice: null,
            eyeDiseases: [],
            cataractEyeType: 0,
            fundusEyeType: 0,
            gilliumEyeType: 0,
            illnessName: '',
            united: undefined
        },
        illnessInfo: {
            informationOne: [],
            informationTwo: '',
            informationThere: '',
        },
        warVeteran: {
            idNumber: '',
            name: '',
            phone: '',
            veteranRelations: '',
            myself: null,
            serviceIdentity: '',
            serviceIdentityStr: null
        },
        // diagnosticReports: [],
        // consentForms: [],
        // hospitalizationRecords: [],
        // invoices: [],
        dictInfo: {
            account_type: null,
            aier_payment: [{name: '', code: '0'}],
            aier_relational: [{value: 0, label: ''}]
        },
        totalMsg: '',
        secondEyeType: null,
        advanceMoney: null,
        illness: [],
        quota: []
    }
}

export function buildAierData(data: any) {

    if (data.files && data.files.length > 0) {
        data.illnessInfo = {informationOne: []}
        if (data.fee) {
            data.fee.diagnosticReports = [];
            data.fee.consentForms = [];
            data.fee.hospitalizationRecords = [];
            data.fee.invoices = [];
            data.fee.voucher = [];
            data.fee.identityPromise = [];
            data.fee.proofProve = [];
            data.fee.preferentialTreatmentCard = [];
        }
        data.account.applicant = [];
        data.account.payee = [];
        data.account.signature = [];
        data.files.forEach((item: { url: string; type: any; name: string, fileId: number }) => {
            const path = API.domain + item.url;
            const obj = {
                id: item.fileId,
                uid: item.fileId,
                fileId: item.fileId,
                name: item.name,
                url: API.domain + item.url,
                status: 'done',
            };
            if (item.type === 1) {
                data.fee.diagnosticReports.push(obj);
            } else if (item.type === 2) {
                data.fee.consentForms.push(obj);
            } else if (item.type === 3) {
                data.fee.hospitalizationRecords.push(obj);
            } else if (item.type === 4) {
                data.fee.invoices.push(obj);
            } else if (item.type === 12) {
                data.fee.identityPromise.push(obj);
            } else if (item.type === 13) {
                data.fee.proofProve.push(obj);
            } else if (item.type === 14) {
                data.fee.preferentialTreatmentCard.push(obj);
            } else if (item.type === 5) {
                data.illnessInfo.informationOne.push(obj);
            } else if (item.type === 8) {
                data.fee.voucher.push(obj);
            } else if (item.type === 9) {
                data.account.applicant.push(obj);
                data.account.applicantShow = path;
            } else if (item.type === 10) {
                data.account.payee.push(obj);
                data.account.payeeShow = path;
            } else if (item.type === 11) {
                data.account.signature.push(obj);
                data.account.signatureShow = path;
            }
        });
    }
    const illnessForm = buildIllness(data);
    if (illnessForm) {
        data.fee.eyeDiseases = illnessForm.eyeDiseases;
        data.fee.cataractEyeType = illnessForm.cataractEyeType;
        data.fee.fundusEyeType = illnessForm.fundusEyeType;
        data.fee.gilliumEyeType = illnessForm.gilliumEyeType;
    }
}

export function buildIllness(data: any) {
    if (data.illness && data.illness.length > 0) {
        let eyeDiseases: string[] = [];
        let cataractEyeType = undefined;
        let fundusEyeType = undefined;
        let gilliumEyeType = undefined;
        data.illness.forEach((item: IllnessDetail) => {
            eyeDiseases.push(item.illnessCode)
            if (CATARACT === item.illnessCode) {
                cataractEyeType = item.eyeType;
            } else if (FUNDUS === item.illnessCode) {
                fundusEyeType = item.eyeType;
            } else if (GILLIUM === item.illnessCode) {
                gilliumEyeType = item.eyeType;
            }
        })
        let result: IllnessForm = {
            eyeDiseases: eyeDiseases,
            cataractEyeType: cataractEyeType,
            fundusEyeType: fundusEyeType,
            gilliumEyeType: gilliumEyeType
        }
        return result;
    }
    return undefined;
}

export const listDefaultData: PageData = {
    searchData: {
        name: '',
        phone: '',
        idCard: '',
        payee: '',
        backCard: '',
        status: undefined,
        provinceId: undefined,
        hospitalId: undefined,
        settDate: undefined,
        executeDate: undefined,
        paymentMethod: undefined,
        activityId: undefined,
        page: 0
    },
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    content: [],
    statusArr: {...statusArr, ...statusArrV2},
    status: 0,
    genderArr: genderArr,
    btnQ: getDetBtnQ(),
    importLoading: false,
    tip: '',
    statusListArr: [],
    isModalOpen: true,
    modalTitle: '',
    importResultData: {},
    statusColorArr: {...statusColorArr, ...statusColorArrV2},
    eyeTypeArrShow: eyeTypeArrShow,
    zoneSelect: [],
    hospitalSelect: [],
    selectId: 0,
    estimateNum: 0,
    estimateAmount: 0,
    selectCityName: '',
    twiceAccount: []
}

export const appletsConfigListDefaultData: AppletsConfigPage = {
    id: 0,
    searchData: {
        hospitalId: undefined,
        provinceId: undefined,
        name: undefined,
        page: 0,
        protocolStatus: undefined,
        activityId: 1
    },
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    content: [],
    btnQ: getDetBtnQ(),
    selectId: 0,
    selectCityName: '',
    tip: '',
    importLoading: false,
    selectCityId: 0,
    selectHospitalId: 0,
    provinceId: 0,
    zoneSelect: [],
    activitySelect: [],
    hospitalSelect: []
}

export const selectStatus = (arr: any[], that: any) => {
    let temp: any[] = [];
    statusSelectArrV2.forEach(item => {
        if (arr.indexOf(item.value) >= 0) {
            temp.push(item);
        }
    })
    that.setState({
        statusListArr: temp
    })
};


export const statsDefaultData: Chart = {

    data: {
        offsetWidth: 0,
        total: 0,
        last7Day: [
            {
                key: "",
                value: 0
            }
        ],
        provinceTotal: [
            {
                key: "",
                value: 0
            }
        ],
        statsTotal: [
            {
                key: "",
                value: 0
            }
        ],
        provinceStats: [
            {
                name: "",
                totalUser: 0,
                completeSurgery: 0,
                completeReimburse: 0,
                toReviewed: 0,
                auditFailed: 0,
                waitScreening: 0,
                waitSurgery: 0,
                notSurgery: 0,
                reimburseFail: 0,
                reconciling: 0
            }
        ]
    },
    dayStats: {
        provinceStats: [{
            name: "",
            totalUser: 0,
            completeSurgery: 0,
            completeReimburse: 0,
            completeSurgeryCases: 0,
            completeReimburseCases: 0,
            msg: ''
        }]
    }
}

// const Veteran = {
//     cardTitle: '身份信息',
//     myself: '是否是老兵本人',
//     warVeteranName: '老兵姓名',
//     warVeteranIdNumber: '老兵身份证号',
//     warVeteranRelations: '申请人与老兵关系',
//     warVeteranPhone: '老兵手机号',
//     warVeteranShow: '老兵本人',
//     proofOfIdentity: "退伍老兵优待证"
// }
// const service = {
//     cardTitle: '身份信息',
//     myself: '是否是服务站工作人员本人',
//     warVeteranName: '工作人员姓名',
//     warVeteranIdNumber: '工作人员身份证号',
//     warVeteranRelations: '申请人与工作人员关系',
//     warVeteranPhone: '工作人员手机号',
//     warVeteranShow: '工作人员本人',
//     proofOfIdentity: "退休证"
// }

const corps = {
    cardTitle: '身份信息',
    myself: '是否是兵团职工本人',
    warVeteranName: '兵团职工姓名',
    warVeteranIdNumber: '兵团职工身份证号',
    warVeteranRelations: '申请人与兵团职工关系',
    warVeteranPhone: '兵团职工手机号',
    warVeteranShow: '兵团职工本人',
    proofOfIdentity: "退休证"
}

export const AierServiceIdentityMap = {
    // '1': Veteran,
    // '2': service,
    '3': corps
}

export const DescEyeDisease = [
    {
        value: 1,
        label: '本人白内障&眼底病',
    },
    {
        value: 2,
        label: '本人白内障',
    },
    {
        value: 3,
        label: '本人眼底病',
    },
    {
        value: 4,
        label: '本人翼状胬肉',
    },

    {
        value: 5,
        label: '家属白内障&眼底病',
    },
    {
        value: 6,
        label: '家属白内障',
    },
    {
        value: 7,
        label: '家属眼底病',
    },
    {
        value: 8,
        label: '家属翼状胬肉',
    }]

export const DescEyeDiseaseSimple = [
    {
        value: 1,
        label: '白内障&眼底病',
    },
    {
        value: 2,
        label: '白内障',
    },
    {
        value: 3,
        label: '眼底病',
    },
    {
        value: 4,
        label: '翼状胬肉',
    }]

export const ActivitySelectList = [
    {
        value: 1,
        label: '情暖老兵守护光明',
    },
    {
        value: 5,
        label: '情暖兵团守护光明',
    },
]


export function getZoneRoleId(activityId: any) {

    if (activityId === 1) {
        return 1;
    } else if (activityId === 5) {
        return 6;
    }
}

export const AIER_id = 1;

export const CORPS_ID = 5;

export const ActivityMap = {
    1: '情暖老兵·守护光明',
    5: '情暖兵团·守护光明'
}

export const ActivityColor = {
    1: "rgb(24,56,140)",
    5: 'rgb(255,189,28)'
}

export function getDetBtnQ() {
    return {
        R286: 0,
        R287: 0,
        R288: 0,
        R289: 0,
        R290: 0,
        R291: 0,
        R292: 0,
        R293: 0,
        R294: 0,
        R295: 0,
        R296: 0,
        R297: 0,
        R298: 0,
        R299: 0,
        R300: 0,
        R302: 0,
        R303: 0,
        R304: 0,
        R305: 0,
        R306: 0,
        R307: 0,
        R308: 0,
        R309: 0,
        R310: 0,
        R311: 0,
        R312: 0,
        R313: 0,
        R314: 0,
        R315: 0,
        R316: 0,
        R317: 0,
        R318: 0,
        R319: 0,
        R320: 0,
        R321: 0,
        R322: 0,
        R323: 0,
        R324: 0,
        R325: 0,
        R326: 0,
        R327: 0,
        R328: 0,
        R329: 0,
        R330: 0,
        R331: 0,
        R332: 0,
        R333: 0,
        R334: 0,
        R335: 0,
        R336: 0,
        R337: 0,
    }
}